<template>
  <Dialog
    ref="dialog"
    class="fast-faq-dialog modal-dialog--size-to-content modal-dialog--width-large"
    v-model="computedValue"
  >
    <DialogHeader>
      <!-- {{ computedNodes.name }} -->
    </DialogHeader>

    <DialogContent>
      <div
        v-for="node in computedNodes"
        :key="node.name"
        class="fast-faq-dialog__item"
      >
        <div v-html="marked(node.name)" class="fast-faq-dialog__header" />
        <div v-html="marked(node.content)" />
      </div>
    </DialogContent>
    <DialogFooter>
      <Btn
        class="range-preview-dialog__close"
        @click="$refs.dialog.closeModal()"
      >
        {{ localization.general.Simple.Close }}
      </Btn>
    </DialogFooter>
  </Dialog>
</template>

<script>
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader
} from '@/components/UI/Dialog';
import Btn from '@/components/UI/Btn';
import marked from 'marked';
// import { mapGetters } from 'vuex';

export default {
  name: 'FastFaqDialog',
  components: {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    Btn
  },
  props: {
    itemNodes: Array,
    activeFaqItems: Array,
    value: {
      type: Boolean
    }
  },
  methods: {
    marked(val) {
      if (val) return marked.parseInline(val);
      return '';
    }
  },

  computed: {
    // ...mapGetters({
    //   localization: 'user/localization'
    // }),
    computedNodes() {
      if (this.activeFaqItems.length) {
        let nodes2 = this.activeFaqItems.map(
          item => this.localization.faq[item]
        );
        console.log(nodes2, 'NODES2');
        return nodes2;
      }

      let nodes = this.itemNodes.map(
        index => this.localization.faq.faqItems[0].nodes[index]
      );
      console.log(nodes, 'NODEESS');
      return nodes;
    },

    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    },
    currentItems() {
      return this.$props.items;
    }
  }
};
</script>

<style lang="scss" src="./FastFaqDialog.scss" />
